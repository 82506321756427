import React from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import ApiClient from '../ApiClient';
import ReactLoading from 'react-loading';
import './paymentCheckout.css';
import { SessionStatus, TransactionStatus } from '../common/constans';
import UTILS from '../common/utils';
import PoweredByBox from '../common/poweredByBox';


const { savePaymentMethod, getPaymentMethodSessionInfo, verify } = ApiClient();


class PaymentMethodCheckout extends React.Component {
    constructor(props) {
        super(props);
        let showNotFound = false;
        let params = new URLSearchParams(window.location.search);
        if (params.has('token')) {
            this.authToken = params.get('token');
        } else {
            showNotFound = true;
        }

        if (params.has('session_id')) {
            this.sessionId = params.get('session_id');
        } else {
            this.sessionId = null;
        }

        this.primaryColor = UTILS.getPrimaryColor();
        this.state = {
            payment_number: '',
            valid_payment_number: false,
            show_loading: false,
            show_not_found: showNotFound,
            show_authentication_view: false,
            authentication_view_html: '',
            save_button_clicked: false,
            is_authorization_amount_required: false,
            show_session_completed: false,
            error_message: '',
            expiry_date: '',
            valid_expiration_month: false,
            valid_expiration_year: false,
            cardholder_name: '',
            valid_cardholder_name: false,
            cvv: '',
            valid_cvv: false,
            success_url: '',
            failure_url: '',
        };
    }

    async componentDidMount() {
        if (!this.state.show_not_found) {
            await this.getSessionInfo();
        }
    }

    async getSessionInfo() {
        this.setState({ "show_loading": true });
        await getPaymentMethodSessionInfo(this.authToken, this.sessionId, this.onGetSessionInfosSuccess, this.onError);
    }

    onGetSessionInfosSuccess = (responseDto) => {
        if (this.ObjectContains(responseDto, "succeeded") && responseDto["succeeded"]) {
            if (responseDto["session_status"] === SessionStatus.Completed) {
                this.setState({ "show_session_completed": true, "show_loading": false });
            }

            this.setState({
                "show_loading": false,
                "is_authorization_amount_required": responseDto["is_authorization_amount_required"],
                "success_url": responseDto["success_url"],
                "failure_url": responseDto["failure_url"],
            });
        }
        else if (this.ObjectContains(responseDto, "succeeded") && !responseDto["succeeded"]) {
            window.location.replace(responseDto["failure_url"] + "?error_code=" + responseDto["error_code"] + "&error_message=" + responseDto["error_message"]);
        }
        else {
            this.setState({ "show_loading": false });
            alert("Something went wrong, try again later");
        }
    }

    async verify() {
        this.setState({ "show_loading": true });
        const requestDto = this.createVerifyRequestDto();
        await verify(requestDto, this.authToken, this.onVerifySuccess, this.onError);
    }

    createVerifyRequestDto = () => {
        return {
            "payment_number": this.state.payment_number.replace(/ /g, ''),
            "expiration_month": this.getExpirationMonth(),
            "expiration_year": this.getExpirationYear(),
            "first_name": this.getFirstName(),
            "last_name": this.getLastName(),
            "security_code": this.state.cvv,
            "should_save_payment_method": true,
            "session_id": this.sessionId,
        }
    }

    onVerifySuccess = (responseDto) => {
        if (this.ObjectContains(responseDto, "succeeded") && responseDto["succeeded"]) {
            if (!responseDto["error_message"] && responseDto["transaction_status"] === TransactionStatus.Successful) {
                window.location.replace(responseDto["redirection_url"] + "?" + this.toQueryParameters(responseDto))
            } else {
                if (!responseDto["error_message"]) {
                    responseDto["error_message"] = "Payment failed";
                }

                window.location.replace(responseDto["redirection_url"] + "?error_code=" + responseDto["error_code"] + "&error_message=" + responseDto["error_message"]);
            }
        }
        else if (this.ObjectContains(responseDto, "succeeded") && !responseDto["succeeded"]) {
            this.setState({
                "show_loading": false,
                "show_authentication_view": false,
                "error_message": responseDto["error_message"]
            });

            window.location.replace(this.state.failure_url + "?error_code=" + responseDto["error_code"] + "&error_message=" + responseDto["error_message"]);
        }
        else {
            if (!responseDto["error_message"]) {
                responseDto["error_message"] = "Something went wrong";
            }

            window.location.replace(this.state.failure_url + "?error_code=" + responseDto["error_code"] + "&error_message=" + responseDto["error_message"]);
        }
    }

    handleChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    validateFormFields() {
        let valid = this.validatePaymentNumber() &
            this.validateExpirationMonth() &
            this.validateExpirationYear() &
            this.validateCardholderName();

        if (this.state.is_authorization_amount_required) {
            valid = valid & this.validateCVV();
        }

        return valid;
    }

    validateCVV() {
        const valid = this.state.cvv.length === 3;
        this.setState({ "valid_cvv": valid });
        return valid;
    }

    validatePaymentNumber() {
        // 19 because of the 3 separated spaces
        const valid = this.state.payment_number.length === 19;
        this.setState({ "valid_payment_number": valid });
        return valid;
    }

    getFirstName = () => {
        return this.state.cardholder_name.split(' ')[0];
    }

    getLastName = () => {
        const names = this.state.cardholder_name.split(' ');
        let lastName = '';
        for (let i = 1; i < names.length; i++) {
            if (i !== 1) lastName += ' ';
            lastName += names[i];
        }
        return lastName;
    }

    validateCardholderName() {
        const valid = this.state.cardholder_name.length > 0;
        this.setState({ "valid_cardholder_name": valid });
        return valid;
    }

    getExpirationMonth() {
        return +this.state.expiry_date.substring(0, 2);
    }

    getExpirationYear() {
        const year = Math.floor(new Date().getFullYear() / 100) * 100;
        return year + (+this.state.expiry_date.substring(3, 5));
    }

    validateExpirationMonth() {
        const month = this.getExpirationMonth();
        const valid = month >= 1 && month <= 12;
        this.setState({ "valid_expiration_month": valid });
        return valid;
    }

    validateExpirationYear() {
        const valid = this.state.expiry_date.length === 5;
        this.setState({ "valid_expiration_year": valid });
        return valid;
    }

    async onSubmit(e) {
        e.preventDefault();
        this.setState({ "save_button_clicked": true });
        if (this.validateFormFields()) {
            if (this.state.is_authorization_amount_required) {
                await this.verify();
            } else {
                await this.savePaymentMethod();
            }
        }
    }

    async savePaymentMethod() {
        this.setState({ "show_loading": true });
        const requestDto = this.createSavePaymentMethodRequestDto();
        await savePaymentMethod(requestDto, this.authToken, this.onSaveSuccess, this.onError);
    }

    createSavePaymentMethodRequestDto = () => {
        return {
            "payment_number": this.state.payment_number.replace(/ /g, ''),
            "expiration_month": this.getExpirationMonth(),
            "expiration_year": this.getExpirationYear(),
            "first_name": this.getFirstName(),
            "last_name": this.getLastName(),
            "session_id": this.sessionId,
        }
    }

    toQueryParameters(response) {
        return Object.keys(response)
            .filter(key => key !== 'redirection_url')
            .map(key => {
                return encodeURIComponent(key) + "=" + encodeURIComponent(JSON.stringify(response[key]));
            }).join('&');
    }

    ObjectContains = (dtoObject, internalObject) => {
        return dtoObject !== null && internalObject in dtoObject;
    }

    onSaveSuccess = (responseDto) => {
        if (this.ObjectContains(responseDto, "succeeded") && responseDto["succeeded"]) {
            if (!responseDto["error_message"]) {
                window.location.replace(responseDto["redirection_url"] + "?" + this.toQueryParameters(responseDto["payment_method"]))
            } else {
                window.location.replace(responseDto["redirection_url"] + "?error_code=" + responseDto["error_code"] + "&error_message=" + responseDto["error_message"]);
            }
        }
        else if (this.ObjectContains(responseDto, "succeeded") && !responseDto["succeeded"]) {
            this.setState({
                "show_loading": false,
                "show_authentication_view": false,
                "error_message": responseDto["error_message"]
            });
        }
        else {
            this.setState({
                "show_loading": false,
                "show_authentication_view": false,
                "error_message": 'Something went wrong'
            });
            alert("Something went wrong");
        }
    }

    onError = (error) => {
        this.resetState();
        console.log(error);
        alert(error.message);
    }

    resetState() {
        this.setState({
            payment_number: '',
            valid_payment_number: false,
            expiry_date: '',
            valid_expiration_month: false,
            cardholder_name: '',
            valid_expiration_year: false,
            show_loading: false,
            show_not_found: false,
            save_button_clicked: false
        })
    }

    showNotFound() {
        return (
            <div>
                <h3 className='text-center text-danger mt-4'>Session not found!</h3>
                <br />
                <PoweredByBox />
                <br />
            </div>
        )
    }

    validateNumeric(event) {
        const value = event.target.value;
        if (value.length === 0) return;

        const lastDigit = value[value.length - 1];
        const reg = new RegExp('^[0-9]+$');
        if (!reg.test(lastDigit)) {
            event.target.value = value.slice(0, -1);
        }
    }

    formatCardNumber(event) {
        let value = event.target.value.replace(/ /g, '');
        if (value.length < 4) return;
        let splittedArr = value.match(/.{1,4}/g);
        event.target.value = splittedArr.join(' ');
    }

    formatExpiryDate(event) {
        let date = event.target.value;
        if (date.length === 2 && this.state.expiry_date.length === 1) {
            event.target.value = date + '/';
        }
        else if (date.length === 3 && this.state.expiry_date.length === 2) {
            event.target.value = this.state.expiry_date + '/' + date[date.length - 1];
        }
    }

    handleBorderDangerClass(valid) {
        return valid || !this.state.save_button_clicked ? '' : 'border-danger';
    }

    showErrorMessageIfFound() {
        if (this.state.error_message.length > 0) {
            return (<div className="row">
                <div className="text-center alert alert-danger">
                    {this.state.error_message}
                </div>
            </div>);
        }

        return (<div></div>)
    }

    getButtonName = () => {
        return this.state.is_authorization_amount_required ? 'Verify' : 'Add'
    }

    showForm() {
        return (
            <div>
                {this.showErrorMessageIfFound()}
                <Form className="ml-3 mt-3">
                    <h4 className='text-center'>Add your card</h4>
                    <br />
                    <Form.Group controlId="cardholder_name" className="pt-3">
                        <Form.Control className={this.handleBorderDangerClass(this.state.valid_cardholder_name)} type="text" maxLength={50} placeholder="Cardholder name" onChange={this.handleChange} />
                    </Form.Group>
                    <div className="row mt-2">
                        <Form.Group controlId="payment_number" className="pt-3" >
                            <Form.Control className={this.handleBorderDangerClass(this.state.valid_payment_number)} type="text" onInput={(event) => { this.validateNumeric(event); this.formatCardNumber(event); }} maxLength={19} placeholder="Card Number" onChange={this.handleChange} />
                        </Form.Group>
                    </div>
                    <div className="row mt-2">
                        <div className={this.state.is_authorization_amount_required ? "col-6" : "col-12"}>
                            <Form.Group controlId="expiry_date" className="pt-3">
                                <Form.Control className={this.handleBorderDangerClass(this.state.valid_expiration_month && this.state.valid_expiration_year)} type="text" maxLength={5} onInput={(event) => { this.validateNumeric(event); this.formatExpiryDate(event); }} placeholder="MM/YY" onChange={this.handleChange} />
                            </Form.Group>
                        </div>
                        {
                            this.state.is_authorization_amount_required ? (
                                <div className="col-6">
                                    <Form.Group controlId="cvv" className="pt-3">
                                        <Form.Control className={this.handleBorderDangerClass(this.state.valid_cvv)} type="text" placeholder="CVV" maxLength={3} onInput={(event) => this.validateNumeric(event)} onChange={this.handleChange} />
                                    </Form.Group>
                                </div>
                            ) : null
                        }
                    </div>
                </Form>
                <br />
                <div className="text-center mt-3">
                    <button className="btn btn-md primary-btn pl-4 pr-4" style={{ width: "50%" }} onClick={(e) => this.onSubmit(e)}>
                        {this.getButtonName()}
                    </button>
                </div>
                <br />
                <PoweredByBox />
                <br />
            </div>)
    }

    showLoading() {
        return (
            <div>
                <div
                    style={{
                        width: "100%",
                        height: "100",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <ReactLoading type={"spinningBubbles"} height={80} width={80} color={this.primaryColor} />
                </div>
            </div>
        )
    }

    showSessionCompleted() {
        return (
            <div>
                <h3 className='text-center text-danger mt-4'>Session has been completed before!</h3>
                <br />
                <PoweredByBox />
                <br />
            </div>
        )
    }

    getMainClassName() {
        if (this.state.show_loading !== true) {
            return "col-lg-5 p-4 main-box";
        }

        return "col-lg-5 p-4";
    }

    render() {
        return (
            <Container style={{ marginTop: "50px" }}>
                <div className="row p-4">
                    <div className='col-lg-3'></div>
                    <div className={this.getMainClassName()}>
                        {
                            this.state.show_not_found ? this.showNotFound() :
                                this.state.show_session_completed ? this.showSessionCompleted() :
                                    this.state.show_loading ? this.showLoading() : this.showForm()
                        }
                    </div>
                </div>
            </Container>
        );
    }
}

export default PaymentMethodCheckout;