import { v4 as uuidv4 } from 'uuid';

async function Send(actualSending, success, failure) {
    try {
        let response = await actualSending();
        if (response.status === 401) {
            throw new Error('Your session has expired, please start a new one');
        }

        if (success) {
            success(await response.json());
        }
    } catch (error) {
        if (failure) {
            failure(error);
        }
        else {
            console.log(error);
        }
    }
}

function constructAcceptanceRequest(body) {
    return {
        request_id: uuidv4(),
        request_body: body
    }
}

function constructSessionIdAsQueryParameter(sessionId) {
    if (sessionId === null || sessionId === undefined) {
        return '';
    }

    return `?session_id=${sessionId}`;
}

const ApiClient = () => {
    const acceptanceBaseUrl = process.env.REACT_APP_API_ENDPOINT;
    const paymentBaseUrl = process.env.REACT_APP_PAYMENT_API_ENDPOINT;

    const getAuthenticationViewPath = "/api/Transaction/authentication/view";
    const getAuthenticationStatusPath = "/api/Transaction/authentication/status";
    const getTransactionSessionInfoPath = "/api/Transaction/session";
    const getPaymentMethodSessionInfoPath = "/api/PaymentMethod/session";
    const payPath = "/api/Transaction/pay";
    const verifyPath = "/api/Transaction/verify";
    const savePaymentMethodPath = "/api/PaymentMethod";
    const getLiveLinkDetailsPath = "/api/PaymentLinkGeneration/details";
    const initLiveLinkPaymentPath = "/api/PaymentLinkGeneration/payment";

    const pay = async (requestBody, authToken, success, failure) => {
        return await Send(async () => {
            return await fetch(paymentBaseUrl + payPath, {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                method: 'POST',
                body: JSON.stringify(requestBody),
            });
        }, success, failure);
    };

    const verify = async (requestBody, authToken, success, failure) => {
        return await Send(async () => {
            return await fetch(paymentBaseUrl + verifyPath, {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                method: 'POST',
                body: JSON.stringify(requestBody),
            });
        }, success, failure);
    };

    const getAuthenticationView = async (requestBody, authToken, success, failure) => {
        return await Send(async () => {
            return await fetch(paymentBaseUrl + getAuthenticationViewPath, {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                method: 'POST',
                body: JSON.stringify(requestBody),
            });
        }, success, failure);
    };

    const getAuthenticationStatus = async (authToken, sessionId, success, failure) => {
        return await Send(async () => {
            return await fetch(paymentBaseUrl + getAuthenticationStatusPath + constructSessionIdAsQueryParameter(sessionId), {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                method: 'GET'
            });
        }, success, failure);
    };

    const savePaymentMethod = async (requestBody, authToken, success, failure) => {
        return await Send(async () => {
            return await fetch(paymentBaseUrl + savePaymentMethodPath, {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                method: 'POST',
                body: JSON.stringify(requestBody),
            });
        }, success, failure);
    };

    const getTransactionSessionInfo = async (authToken, sessionId, success, failure) => {
        return await Send(async () => {
            return await fetch(paymentBaseUrl + getTransactionSessionInfoPath + constructSessionIdAsQueryParameter(sessionId), {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                method: 'GET'
            });
        }, success, failure);
    };

    const getPaymentMethodSessionInfo = async (authToken, sessionId, success, failure) => {
        return await Send(async () => {
            return await fetch(paymentBaseUrl + getPaymentMethodSessionInfoPath + constructSessionIdAsQueryParameter(sessionId), {
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                method: 'GET'
            });
        }, success, failure);
    };

    const getLiveLinkDetails = async (profileName, encryptedOrder, success, failure) => {
        return await Send(async () => {
            return await fetch(`${acceptanceBaseUrl}${getLiveLinkDetailsPath}/${profileName}`, {
                headers: {
                    'content-type': 'application/json',
                    'encrypted-order': encryptedOrder,
                },
                method: 'GET'
            });
        }, success, failure);
    };

    const initLiveLinkPayment = async (profileName, requestBody, encryptedOrder, success, failure) => {
        const request = constructAcceptanceRequest(requestBody);

        return await Send(async () => {
            return await fetch(`${acceptanceBaseUrl}${initLiveLinkPaymentPath}/${profileName}`, {
                headers: {
                    'content-type': 'application/json',
                    'encrypted-order': encryptedOrder,
                },
                method: 'POST',
                body: JSON.stringify(request),
            });
        }, success, failure);
    };

    return {
        pay: pay,
        verify: verify,
        getAuthenticationView: getAuthenticationView,
        getAuthenticationStatus: getAuthenticationStatus,
        savePaymentMethod: savePaymentMethod,
        getTransactionSessionInfo: getTransactionSessionInfo,
        getPaymentMethodSessionInfo: getPaymentMethodSessionInfo,
        getLiveLinkDetails: getLiveLinkDetails,
        initLiveLinkPayment: initLiveLinkPayment,
    }
};

export default ApiClient;